<template>
  <div class="box">
    <div class="headline">文件学习</div>
    <div class="content">
      <p>第一次进去需要点击获取抽题数</p>
      <img src="../../assets/specificationImgs/study9.png" width="100%" />
      <br />
      <br />
      <br />
      <br />
      <p>学习完成后</p>
      <p>不可重复学习</p>
      <img src="../../assets/specificationImgs/study10.png" width="100%" />
      <br />
      <br />
      <br />
      <br />
      <p>转派</p>
      <p>点击转派按钮可转派他人</p>
      <img src="../../assets/specificationImgs/study11.png" width="100%" />
      <br />
      <br />
      <p>选择人员</p>
      <p>点击确认 即可转派他人</p>
      <img src="../../assets/specificationImgs/study12.png" width="100%" />
      <br />
      <br />
      <br />
      <br />
      <p>进入学习中心界面，点击左侧【学习中心】，如下图</p>
      <img src="../../assets/specificationImgs/study13.png" width="100%" />
      <br />
      <br />
      <p>该图显示的是当前用户本月所学习的记录详情</p>
      <img src="../../assets/specificationImgs/study14.png" width="100%" />
      <br />
      <br />
      <br />
      <p>
        用户可以点击“抽题数目”，如果存在未完成的学习记录，系统会直接显示未完成的记录，也可以强制放弃之前的记录，重新开始学习。
      </p>
      <img src="../../assets/specificationImgs/study15.png" width="100%" />
      <p>在题目列表中点击学习按键后，系统会记录已经学习</p>
      <br />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>